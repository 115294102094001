<template>
  <div class="secondHandCar" ref="HANDCAR">
    <div class="handCarTop">
      <div class="searTop">
        <van-search v-model="searchWords" placeholder="请输入搜索关键词" @search="onSearch" @clear="onCancel" clear-trigger="always" />
        <van-icon name="add-square" @click="toSelectBrand" />
      </div>
      <van-swipe class="my-swipe" :autoplay="isAutoPlay" indicator-color="white" @change="changeSwipe">
        <van-swipe-item v-for="item in swiperList" :key="item.bannerUrl"> 
          <img v-if="item.bannerUrl.indexOf('png') > -1" :src="item.bannerUrl" alt="" @click="swperGo(item)">
          <video 
            v-if="item.bannerUrl.indexOf('mp4') > -1" 
            @ended="endVideo" 
            :src="item.bannerUrl" 
            @click="swperGo(item)" 
            autoplay 
            muted 
            poster="@/assets/image/videoimg.png"
            controls="controls" 
            webkit-playsinline=“true” 
            playsinline=“true” 
            x5-video-orientation=“landscape|portrait” 
            x5-video-player-type=“h5-page”
          />
        </van-swipe-item>
      </van-swipe>
    </div>
    <van-pull-refresh class="myList" v-model="refreshing" @refresh="onRefresh">
      <van-list
        class="vanList"
        :style="'height:' + tabH + 'px'"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="boxCar" v-for="(item) in list" :key="item.id" :title="item.carBrand" @click="goToDetail(item.id)">
          <img class="carImg" :src="item.carImage[0].url" alt="">
          <div class="boxRight">
            <p class="catTitle">{{item.carBrand}}</p>
            <p class="commonText">{{item.carRegisterAt ? item.carRegisterAt.slice(0, 4) : ''}}年/{{ item.carMileage }}万公里/{{item.carCity}}</p>
            <div class="priceAdndiscounts">
              <p class="symbol">￥</p><p class="price">{{item.carPrice}}</p><p class="unit">万</p>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import { carMainSwipe, carMainList } from '@/api/secondSelect'
export default {
  data() {
    return {
      searchWords: '',
      loading: false,
      finished: false,
      refreshing: false,
      swiperList: [],
      list: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      tabH: 0,
      carBrand: '',
      isAutoPlay: 3000
    }
  },
  created() {
    this.carBrand = this.$route.query.key || ''
    this.getswiper()
  },
  mounted() {
    // this.onLoad()
    this.tabH = this.$refs.HANDCAR.offsetHeight - 275
  },
  methods: {
    // 获取轮播图
    getswiper() {
      carMainSwipe().then(res => {
        this.swiperList = res.data
      })
    },
    swperGo(e) {
      window.open(e.jumpUrl, "_blank")
    },
    /* 搜索事件 */
    onSearch(e) {
      this.pageNum = 1
      this.onLoad()
    },
    onCancel(e) {
      console.log(e)
    },
    onLoad() {
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }
      carMainList({carBrand: this.carBrand, carName: this.searchWords, pageNum: this.pageNum, pageSize: this.pageSize}).then(res => {
        this.pageNum ++
        this.loading = false;
        this.list = this.list.concat(res.data.list) || []
        this.carBrand = ''
        this.total = res.data.total
        console.log(this.total)
        if (this.list.length >= this.total) {
          this.finished = true;
        }
      })
    },
    onRefresh() {
      this.pageNum = 1
      this.searchWords = ''
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    goToDetail(e) {
      this.$router.push({
        path: '/secondcarDetail',
        query: {
          id: e
        }
      })
    },
    toSelectBrand() {
      this.$router.push({
        path: '/selectBrand',
        query: {
          id: 1
        }
      })
    },
    changeSwipe(e) {
      if (this.swiperList[e].bannerUrl.indexOf('mp4') > -1) {
        this.isAutoPlay = 0
      } else {
        this.isAutoPlay = 3000
      }
    },
    endVideo(e) {
      if (e.isTrusted) {
        this.isAutoPlay = 10
      } else {
        this.isAutoPlay = 0
      }
    },
  }
}
</script>

<style scoped lang="scss">
.secondHandCar{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .handCarTop{
    width: 100%;
    height: 166px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    .searTop{
      display: flex;
      align-items: center;
      .van-search{
        width: 340px;
        height: 30px;
        margin-top: 10px;
        background: #F4F5F7;
        border-radius: 9px 9px 9px 9px;
        opacity: 1;
        .van-search__content{
          background: #F4F5F7;
          padding-left: 0;
          height: 30px;
          .van-search .van-cell{
            background: #F4F5F7;
            .van-cell__value--alone{
              color: #99A3B5;
            }
          }
          // .van-icon-clear::before{
          //   z-index: 99999;
          // }
        }
      } 
      i{
        font-size: 20px;
        margin-top: 10px;
        margin-left: 5px;
      }
    }
    
    .van-swipe{
      width: 350px;
      height: 102px;
      border-radius: 8px;
      margin-top: 14px;
      .van-swipe-item {
        width: 350px;
        height: 102px;
        color: #fff;
        font-size: 20px;
        line-height: 102px;
        text-align: center;
        background-color: rgb(57, 169, 237, .1);
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          height: 102px;
        }
        video{
          height: 102px;
        }
      }
    }
  }
  .myList{
    width: 100%;
    overflow-y: scroll;
  }
  .vanList{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .boxCar{
    width: 350px;
    max-height: 102px;
    min-height: 102px;
    margin-bottom: 14px;
    display: flex;
    background: #FFFFFF;
    border-radius: 14px 14px 14px 14px;
    opacity: 1;
    .carImg{
      width: 99px;
      height: 102px;
      border-radius: 14px 0px 0px 14px;
      opacity: 1;
    }
    .boxRight{
      margin-left: 11px;
      padding-top: 8px;
      .catTitle{
        width: 228px;
        height: 25px;
        font-size: 18px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 800;
        color: #212121;
        line-height: 25px;
      }
      .commonText{
        height: 14px;
        margin-top: 4px;
        font-size: 12px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #707070;
        line-height: 12px;
      }
      .priceAdndiscounts{
        margin-top: 20px;
        display: flex;
        align-items: flex-start;
        .symbol{
          font-size: 12px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 800;
          color: #ED5748;
          line-height: 12px;
        }
        .price{
          padding: 0 2px;
          font-size: 18px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 800;
          color: #ED5748;
          line-height: 8px;
        }
        .unit{
          font-size: 10px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 800;
          color: #ED5748;
          line-height: 14px;
        }
      }
    }
  }
}
</style>
